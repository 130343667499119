.text {
   font-family: "Montserrat";
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #666666;
  margin: 12px 0 0;
}

.readMoreLink {
  color: #0189ff;
  cursor: pointer;
  margin-left: 5px;
}

.readMoreLink:hover {
  text-decoration: underline;
}
