.dealItem {
  & h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    /* text-align: left; */
    padding: 0;
    &::first-letter{
      text-transform: capitalize;
    }
  }
  & button {
    background-color: #FFD046;
     font-family: "Montserrat";
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-align: left;
    border-radius: 40px;
    text-align: center;
    min-height: 48px;
    width: 180px;
    margin: 0 auto;
  }
}
