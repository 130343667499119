.reviewCard {
  border-bottom: 1px solid #efeff4;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  max-width: 400px;
  @media (max-width: 450px) {
    max-width: calc(100vw - 32px);
  }
  &:last-child {
    border-bottom: none;
  }
}
.reviewerName {
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #000;
  margin: 0;
}
.reviewHeader {
  display: flex;
  gap: 16px;
  align-items: center;
}
.cardContents {
  display: flex;
  align-items: center;
  gap: 16px;
  & p {
    margin: 0;
  }
}
.reviewDate {
  font-size: 0.875rem;
  color: #888;
  margin: 8px 0 0;
}
.cardHeader{
  display: flex;
  justify-content: space-between;
}
.reviewTitle {
  font-family: Inter;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #000;
  margin: 0;
}
.listingTitle {
  margin: 0;
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #000;
  margin: 6px 0 10px;
}

.reviewDescription {
  margin: 0;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #000;
  margin: 6px 0 16px;
  border-bottom: 1px solid gray;
  padding-bottom: 12px;
}

.rating {
  display: flex;
  margin: 8px 0;
  & svg {
    width: 16px;
    height: 16px;
  }
}

.profileImages {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 100px;
  border: 1px solid rgba(128, 128, 128, 0.112);
  background-color: #0000000F;

}

.profileImage {
  width: 100%;
  height: 100%;
   object-fit: cover;
   border-radius: 100px;
}
.reviewImages {
  display: flex;
  gap: 10px;
  padding-bottom: 6px;
}

.reviewImage {
  width: 124px;
  height: 100px;
   object-fit: cover;
   cursor: pointer;
   @media (max-width: 767px) {
    width: 114px;
    height: 90px;
   }
}

.imageWrapper {
  position: relative;
  border-radius: 4px;
  border: 1px solid rgba(128, 128, 128, 0.433);
  flex-shrink: 0;
}

.showMoreImages {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0000005C;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
}
.modal{
  & img{
    width: 100%;
    height: 100%;
    border-radius: 2px;
  }
  &>div{
    &>div{
      flex-basis: 80% !important;
      @media (max-width: 767px) {
        padding-top: 55px !important;
      }
    }
  }
}
