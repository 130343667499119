@import '../../../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.form {
  flex-grow: 1;
}

.priceCurrencyInvalid {
  color: var(--colorFail);
}
.title {
  margin: 13px 0 0;
   font-family: "Montserrat";
  font-size: 34px;
  font-weight: 500;
  line-height: 40.57px;
  padding: 30px 0 0;
  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 34px;
    margin: 0;
  }
  &::first-letter {
    text-transform: capitalize;
  }
}
.subTitle {
   font-family: "Montserrat";
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;

  margin: 8px 0 31px;
  padding: 0;
  &::first-letter {
    text-transform: capitalize;
  }
}
.accordion {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--colorGrey200);
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.dayText {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  color: #141718;
  margin: 0px 0 8px;
  padding: 0;
}
.accordionText {
   font-family: "Montserrat";
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;

  color: #000;
  margin: 0;
  padding: 0px 0 16px;
}
.description {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.4000000059604645px;

  padding: 0 0 8px;
  border-bottom: 1px solid #6c7275;
  margin: 26px 0 20px;
  color: #141718;
}
.descriptionText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;

  color: #6c7275;
  margin: 0;
  padding: 0;
  &::first-letter {
    text-transform: capitalize;
  }
}
.accordionWrapper {
  max-width: 460px;
}
.accordionTitle {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.4000000059604645px;

  margin: 0 0 0px;
}
.socialIcons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 14px;
  margin: 24px 0 28px;

  & svg,
  & img {
    border: 2px solid #e2e8f0;
    width: 45px;
    height: 45px;
    padding: 4px;
    border-radius: 50px;
  }
}

.submitButton {
  margin: 42px auto 100px;
  min-height: 48px;
  max-width: 327px;
  color: #111;
   font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.02em;

  border-radius: 40px;
  letter-spacing: 0.02em;
  background-color: #ffd046;
}

.imageGrid {
  gap: 15px;
  display: flex;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  & img {
    width: calc(50% - 8px);
    border-radius: 8px;
  }
  & svg, & img{
    width: 200px;
    height: 200px;
    @media (max-width: 767px) {
      width: 140px;
      height: 140px;
      
    }
  }
}
