.bannerContainer {
  position: relative;
  min-height: 96px;
  @media (max-width: 1023px) {
    padding-right: 40px;
    position: unset;
    min-height: auto;
  }
}
.arabicDirection {
  direction: rtl;
  @media (max-width: 1023px) {
    padding-right: 0px !important;
    padding-left: 40px;
  }
  & .poweredByBanner {
    left: 0;
    right: unset !important;
    & .poweredText{
        font-size: 10px;
    }
    & .backIcon {
        z-index: 0;
      & svg{
        transform: rotateY(180deg);
      }
    }
    & .bannerContent {
        left: 6px;
      }
  }
}
.vendorTitle {
 font-family: Actor;
font-weight: 400;
font-size:40px;
line-height: 50.9px;
letter-spacing: 0px;


  color: #000;
  padding: 0;
  margin: 0 0 10px;
  @media (max-width: 1023px) {
    margin: 0 0 6px;
  }
}
.rating {
  font-family: Actor;
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;

  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: #666666;
  padding: 0;
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 10px;
  & svg {
    width: 16px;
    height: 16px;
  }
}
.poweredByBanner {
  position: absolute;
  right: 0;
  top: 0;
  @media (min-width: 1420px) {
    right: -30px;

  }
  & .poweredText {
    /* font-family: SF Pro Text; */
    font-weight: 700;
    font-size: 7px;
    line-height: 8.35px;
    letter-spacing: 0px;
    text-align: center;
    margin: 15px 0 4px;
    padding: 0;
  }
  & .bannerContent {
    position: absolute;
    right: 6px;
    top: 0;
    z-index: 1;
    display: flex;
        flex-direction: column;
        align-items: flex-end;
  }
}
.subTitle {
  font-family: Actor;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #000;
  margin: 0 0 0px;
  padding: 0;
}
.starContent {
  margin: 0 0 30px;
  max-width: 400px;
}
