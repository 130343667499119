.summarySection {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
  @media (max-width: 767px) {
    gap: 22px;
  }
}
.reviewSummaryContainer {
  margin: 24px 0 48px;
}
.progressBar {
  width: 100%;
  height: 15px;
  background-color: rgb(208, 144, 26);
}

.leftSection {
  width: 100%;
}

.totalReviews {
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 166%;
  letter-spacing: 0.4px;
  color: #00000061;
  margin: 4px 0 0;
  padding: 0;
  white-space: pre;
}
.startText {
  font-family: Roboto;
  font-weight: 400;
  font-size: 24px;
  line-height: 133%;
  letter-spacing: 0px;
  vertical-align: middle;
  color: #000000de;
  display: flex;
  align-items: center;
  gap: 7px;
  & svg {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    & path {
      fill: #ffb400;
    }
  }
}
.leftSection > div {
}
.container {
  width: 100%;

  .row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .label, .count {
    width: 50px;
    text-align: right;
    margin-right: 10px;
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: #00000099;
  }

  .progressBar {
    width: 100%;
    height: 10px;
    background-color: #0000000F;
    border-radius: 2px;
    overflow: hidden;
    @media (max-width: 767px) {
      height: 8px;
    }
  }

  .progress {
    height: 100%;
    background-color: #ffc374;
    border-radius: 2px;
  }

  .count{
    width: 20px;
  }
}
