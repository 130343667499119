.listingGrid {
  height: 480px;
  max-width: calc(1365px + 0px);
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 767px) {
    height: 280px;
  }
  @media (min-width: 1800px) {
    margin-right: auto;
  }
  & :global(.slick-dots li button:before) {
    color: #8A8A8F;
    font-size: 10px;
   }
   & :global(.slick-dots li.slick-active button:before) {
    opacity: 1 !important;
  }
  & :global(.slick-dots) {
    bottom: 24px;
  }
  & :global(.slick-slide) {
    & > div {
      margin: 0 2px;
    }
  }
  & :global(.slick-dots li) {
    margin: 0;
    width: 16px;
    height: 16px;
  }
  & :global(.slick-slider) {

  }
  & :global(.slick-track) {
    /* transform: translate3d(0, 0, 0) !important; */
    /* & > div {
      &:last-child {
        & img {
          border-radius: 0px 20px 20px 0;
        }
      }
      &:nth-child(1) {
        & img {
          border-radius: 20px 0 0 20px;
        }
      }
    } */
  }

  & .galleryImage {
    position: relative;
    /* padding-bottom: 121%; */
    &:focus {
      outline: none;
    }

    & img {
      /* position: absolute; */
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      height: 480px;
      border-radius: 15px;
      @media (max-width: 767px) {
        height: 280px;
      }
    }
  }
}
.threeImages {

  /* & :global(.slick-track) {
   @media (min-width: 1024px) {
     transform: translate3d(0, 0, 0) !important;
   }
  } */
}
